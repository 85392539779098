/**
 * @prettier
 */

// React Packages
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';

// Actions
import { completeStage } from './actions/submissionStateActions';

// Services
import apiService from '../services/api/api';
import logService from '../services/shared/logService';
import DataDogService from '../services/shared/datadogService';
import {
    getCompanyName,
    isSubmissionComplete,
    sanitiseImage,
} from '../services/shared/helpers';

//  Components
import Header from './Header';
import Navigator from './Navigator';
import CustomButton from './Button';
import StageComplete from './Messages/StageComplete';

// Images
import livenessAutoSvg from '../assets/img/liveness_auto_instructions.svg';
import loadingGif from '../assets/gifs/loading.gif';
import livenessInstructionGif from '../assets/gifs/passive_instruction.gif';

// Config
import { imageAlt } from '../config/accessabilityRules';
import { ACTION_LABELS } from '../config/dataDogActionLabels';
import InstructionModal from './Messages/InstructionModal';

// ID Live Face SDK Development or Prod version
if (process.env.REACT_APP_IDLIVEFACE_VERSION === 'dev') {
    import('idlive-face-capture-web-development');
} else {
    import('idlive-face-capture-web');
}

const ACTION = ACTION_LABELS.captureSelfie;

class CaptureSelfie extends Component {
    constructor(props) {
        super(props);
        this.primaryFocusRef = React.createRef();
        this.state = {
            loading: true,
            detectionMessage: '',
            navigation: {
                action: 'load',
                props: null,
            },
        };
        this.openCamera = this.openCamera.bind(this);
        this.configureCapture = this.configureCapture.bind(this);
        this.updateMessage = this.updateMessage.bind(this);
        this.submitLivenessImage = this.submitLivenessImage.bind(this);
        this.processImage = this.processImage.bind(this);
    }

    componentDidMount() {
        // Sets focus to primary heading on first render
        if (this.primaryFocusRef && this.primaryFocusRef.current) {
            this.primaryFocusRef.current.focus();
        }

        // Sets document title
        const { t } = this.props;
        document.title = t('idpal_doc_title_liveness');

        // Initialize IDR&D SDK
        document
            .querySelector('idlive-face-capture')
            .addEventListener('initialize', () => {
                this.setState({ loading: false });
            });
    }

    componentDidUpdate(prevProps, prevState) {
        // Sets focus to primary heading on first render
        if (this.primaryFocusRef && this.primaryFocusRef.current) {
            this.primaryFocusRef.current.focus();
        }

        // Sets document title
        const { t } = this.props;
        if (this.state.livenessError) {
            document.title = t('idpal_doc_title_liveness_error');
        } else {
            document.title = t('idpal_doc_title_liveness');
        }
    }

    showInstructionModal() {
        if (this.props.isPoaEnabled) {
            if (
                this.props.docCompleted &&
                this.props.isDocSubmitted &&
                this.props.poaCompleted &&
                this.props.isPoaDocSubmitted
            ) {
                return true;
            }
        }

        if (!this.props.isPoaEnabled) {
            if (this.props.docCompleted && this.props.isDocSubmitted) {
                return true;
            }
        }

        return false;
    }

    openCamera() {
        const idliveFaceCapture = document.querySelector('idlive-face-capture');
        this.configureCapture(idliveFaceCapture);
        idliveFaceCapture.openCamera();
        DataDogService.log('IDR&D Camera Opened');
    }

    /**
     * Configures IDR&D Capture behavior
     * @param {HTMLElement} capture
     */
    configureCapture(capture) {
        // Face has been detected
        capture.addEventListener('faceDetection', event => {
            if (event.detail[0].errors.length === 0) {
                this.updateMessage('CAPTURING');
            } else {
                this.updateMessage(event.detail[0].errors[0]);
            }
        });

        // Capture is about to occur
        capture.addEventListener('beforeCapture', event => {
            this.updateMessage('CAPTURING');
        });

        // Camera was closed
        capture.addEventListener('close', event => {
            DataDogService.log('IDR&D Camera Closed');
            this.updateMessage(null);
        });

        // Capture has occurred
        capture.addEventListener('capture', event => {
            // Log to Datadog
            DataDogService.log('IDR&D Liveness Captured');

            // Clear message, show loader
            this.updateMessage(null);
            this.setState({ loading: true });

            // Send result for processing
            const { photo, bundle } = event.detail[0];
            this.processImage(photo, bundle);
        });

        // Error has occurred
        capture.addEventListener('error', event => {
            // Log Error
            DataDogService.createError(
                'IDR&D Liveness error: ' + event.detail[0]
            );

            // Navigate to native camera fallback
            this.setState({
                navigation: {
                    action: 'next',
                    props: {
                        fallback: true,
                    },
                },
            });
        });
    }

    // Read the image as base64
    processImage(photo, bundle) {
        const reader = new FileReader();
        reader.readAsDataURL(photo);

        reader.onloadend = () => {
            this.submitLivenessImage(reader.result);
        };
    }

    // Translates and updates face detection messages for user
    updateMessage(message) {
        if (message === null) {
            this.setState({ detectionMessage: null });
        } else {
            const { t } = this.props;
            const faceDetectionStates = {
                FACE_NOT_FOUND: 'idpal_move_face',
                TOO_MANY_FACES: 'idpal_too_many_faces',
                FACE_ANGLE_TOO_LARGE: 'idpal_move_face',
                PROBABILITY_TOO_SMALL: 'idpal_move_face',
                FACE_TOO_SMALL: 'idpal_move_closer',
                FACE_CLOSE_TO_BORDER: 'idpal_move_face',
                CAPTURING: 'idpal_capturing',
            };

            this.setState({
                detectionMessage: t(faceDetectionStates[message]),
            });
        }
    }

    // Send Base64 Image to API for validation
    submitLivenessImage(base64Image) {
        const image = sanitiseImage(base64Image);
        apiService
            .getPassiveLivenessResult(image)
            .then(response => {
                DataDogService.log('IDR&D Successfully Captured Selfie.');
                this.props.completeStage('passive_liveness');
                this.setState({
                    navigation: {
                        action: 'next',
                    },
                });
            })
            .catch(error => {
                DataDogService.createError(
                    'Unable to send IDR&D liveness for verification.'
                );
                this.setState({ loading: false });
                logService.error(error);
                this.setState({
                    navigation: {
                        action: 'error',
                        props: {
                            retryAction: () =>
                                this.submitLivenessImage(base64Image),
                            error: error,
                        },
                    },
                });
            });
    }

    render() {
        const { t } = this.props;

        if (isSubmissionComplete()) {
            return (
                <Fragment>
                    <Header />
                    <StageComplete
                        message={t('idpal_your_submission_is_complete', {
                            company: getCompanyName(this.props.companyName),
                        })}
                        hideContinue={true}
                        hideButton={true}
                    />
                </Fragment>
            );
        }

        return (
            <Fragment>
                {this.showInstructionModal() && (
                    <InstructionModal
                        heading={t('idpal_document_upload_completed')}
                        message={t('idpal_document_upload_completed_message')}
                        image={livenessInstructionGif}
                        cta={t('idpal_continue')}
                        showCta={true}
                    />
                )}

                <Header />

                {/* Loading state */}
                {this.state.loading && (
                    <div className='o-site-wrap instructions'>
                        <div className='u-generic-text  u-text-center u-btm-buffer'>
                            <h1
                                ref={this.primaryFocusRef}
                                tabIndex={0}
                                className='loading-ellipse'
                            >
                                {t('idpal_analysing')}
                                <span className='dot1'>.</span>
                                <span className='dot2'>.</span>
                                <span className='dot3'>.</span>
                            </h1>
                        </div>

                        <div className='u-display-loading u-text-center'>
                            <img
                                alt={imageAlt.loading}
                                src={loadingGif}
                                className='capture'
                            />
                        </div>
                    </div>
                )}

                {!this.state.loading && (
                    <div className='o-site-wrap instructions'>
                        <div>
                            <h1
                                className='u-generic-text  u-text-center'
                                ref={this.primaryFocusRef}
                                tabIndex={0}
                            >
                                {t('idpal_time_for_liveness_test')}
                            </h1>

                            <div className='u-display-selfie u-text-center'>
                                <div className='u-display-img-wrap'>
                                    <img
                                        alt={imageAlt.selfieInstructions}
                                        className='capture'
                                        src={livenessAutoSvg}
                                    />
                                </div>
                            </div>

                            <p className='u-generic-text  u-text-center'>
                                {t('idpal_position_your_face_in_oval')}
                            </p>

                            <div className='u-generic-text u-text-center'>
                                <CustomButton
                                    id={'capture'}
                                    className='btn'
                                    label={t('idpal_open_camera')}
                                    handleClick={this.openCamera}
                                    actionDataLabel={
                                        ACTION.openFrontCameraButton
                                    }
                                />
                            </div>
                        </div>
                    </div>
                )}

                {this.state.detectionMessage && (
                    <div className='liveness-message'>
                        {this.state.detectionMessage}
                    </div>
                )}

                <idlive-face-capture></idlive-face-capture>

                <Navigator
                    page={'passive_liveness'}
                    action={this.state.navigation.action}
                    propsToPass={this.state.navigation.props}
                />
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        isDocSubmitted: state.config.isDocSubmitted,
        isPoaDocSubmitted: state.config.isPoaDocSubmitted,
        docCompleted:
            state.submissionState.submissionState.document_upload.completed,
        poaCompleted:
            state.submissionState.submissionState.poa_upload.completed,
        isPoaEnabled: state.submissionState.screens.poa_upload.enabled,
        companyName: state.config.profile.data.company_branding.name,
    };
}

function mapDispatchToProps(dispatch) {
    const actions = bindActionCreators({ completeStage }, dispatch);
    return { ...actions, dispatch };
}

export default withTranslation('translation')(
    connect(mapStateToProps, mapDispatchToProps)(CaptureSelfie)
);
